import React, {useState} from 'react';
import '../popups/DatePopUp.css';
import { CardsArray } from '../CardsArray';
import { finalNumber } from '../Randomizer';
import { useRandomizer } from "../storeApi";
import {TodayNumber, BirthdayNumber} from '../Randomizer';
import DatePicker from 'react-date-picker';

const body = document.querySelector('body')
export let birthDate = null;



 const thirdView = () => {
   setTimeout(() => {
    body.classList.add('third')
    body.classList.add('movecard')
    body.classList.remove('date')
    body.classList.remove('carddeck')
    body.classList.remove('second')
    body.classList.remove('start')
   }, 500);

  setTimeout(() => {
    body.classList.add('light')
    body.classList.remove('green')
  }, 3500);
}

function DatePopUp() {
  
  const { randomize } = useRandomizer();
  const [value, onChange] = useState(new Date());

birthDate = value;


      return (
        <div className="dateform">
        <p>określ datę urodzenia</p>
        <p className="desktop">Na tej podstawie algorytm losowania Runy Dnia dostosuje się do Twojej numerologicznej drogi życia. Żadne informacje nie są zapisane na stałe. Możesz pominąć ten krok. Wybrana data jest jedynie formą personalizacji.</p>
        <p className="mobile">Algorytm losowania Runy Dnia dostosuje się dla Twojej numerologicznej drogi życia. Informacje nie są zapisywane.</p>
        <div className="datePickerWrapper">
        <DatePicker
                   onChange={onChange}
                   value={value}
                   format={"dd/MM/yyyy"}>
          </DatePicker>
          <div className="check">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14">
               <path id="Kształt_3" data-name="Kształt 3" class="cls-1" d="M1,8l5,5L16,1"/>
             </svg>
          </div>
          </div>
          <div className='buttonbox'>
          <button onClick={ () => {
                  TodayNumber();
                  randomize();
                  console.log(finalNumber)
                      thirdView();
                      setTimeout(() => {
                        body.classList.add(CardsArray[finalNumber - 1].titles)
                      }, 2600);
          }}>pomiń</button>
          <button onClick={ () => {                  
            BirthdayNumber()
                  randomize();
                  if (birthDate !== null)
                      thirdView();
                      setTimeout(() => {
                        body.classList.add(CardsArray[finalNumber - 1].titles)
                      }, 2600);
                      return birthDate
          }}>personalizuj</button>
          </div>
          </div>
        )
    }

export default DatePopUp;