import React from 'react';

const Slider = () => {
        return (
            <div className="slider desktop">
            <p className="number">0</p>
            <span className="progress-bar"></span>
            <p>03</p>
        </div>
        )
    }
export default Slider