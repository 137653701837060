export const CardsArray = [

// 1 //

    {
      "titles": "FEHU",

      "nameNumber": "Aett I, runa nr 1",

      "meaning": " Bydło/stado, bogactwo, urodzaj.",

      "otherNames": "Feoh, Fe",

      "elementName" : "Ogień",

      "zodiacName" : "Znak Byka",

      "numerology" : "6",

      "cardNumber" : "1",

      "letter" : "F",

      "meaningTitle": "Runa Pomyślności",
      
      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do bydła, które dawniej było wyznacznikiem poziomu bogactwa. Za pośrednictwem mądrego gospodarowania i aktywnego działania człowiek się wzbogaca.",

      "aboutSecond": "To dobry czas do osiągnięcia celu poprzez działanie. Posiadasz stosowne moce aby zdobyć to czego pragniesz. Poczuj wewnętrzny ogień. Zaufaj energi twórczej. Fehu to obfitość.",

      "aboutThird": "Wskazówka: Bogactwo jest stanem posiadania, który pozwala dzielić się z innymi. Z każdym kogo chcesz docenić. Wszechświat chce dla Ciebie dobrobytu i miłości.",

      "elementImage" : require('../dynamic/thirdview/assets/1/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/1/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/1/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/1/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/1/light.svg').default,

      "theme" : "#C90118",
    },

    // 2 //

    {
      "titles": "URUZ",

      "nameNumber": "Aett I, runa nr 2",

      "meaning": " Tur",

      "otherNames": "Ur",

      "elementName" : "Ziemia",

      "zodiacName" : "Znak Panny",

      "numerology" : "31",

      "cardNumber" : "2",

      "letter" : "U",

      "meaningTitle": "Runa Wytrwałości",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do Tura, dzikiego przodka Byka. Odznaczał się on ogromną wytrzymałością.",

      "aboutSecond": "Miej odwagę dążyć do celu, rezygnując przy tym z tego co zbędne na Twojej drodze do osiągnięcia celu. To manifestacja nieograniczonego potencjału. Uwolnienie energii poprzez pokonanie własnych słabości. Miej odwagę, a otworzy to przed Tobą wrota nowych możliwości.",

      "aboutThird": "Runa uruz niesie ugruntowanie i możliwość przekształcenia energii myśli do świata materialnego.",

      "elementImage" : require('../dynamic/thirdview/assets/2/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/2/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/2/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/2/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/2/light.svg').default,

      "theme" : "#650022"
    },

    // 3 //

    {
      "titles": "THURISAZ",

      "nameNumber": "Aett I, runa nr 3",

      "meaning": "Thor, olbrzym, siłacz, cierń, kolec",

      "otherNames": "Durisaz, Thurisar, Thurs, Thorn, Th",

      "elementName" : "Powietrze",

      "zodiacName" : "Znak Lwa",

      "numerology" : "21",

      "cardNumber" : "3",

      "letter" : "Th",

      "meaningTitle": "Runa Porządku Natury",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do Thora - boga burzy, piorunów. Można ją porównać do Mjöllnir’a. Pomaga chronić naturalnego porządku. Chroni przed wrogimi działaniami.",

      "aboutSecond": "Miej na uwadze pierwotną energią instynktów. Drzemią w Tobie inspirujące siły. Wykaż się inicjatywą. Thurisaz pomaga pozbyć się lęków i strachu.",

      "aboutThird": "Kolec oznacza ochronę lub atak. Nie wyciągaj pochopnych wniosków. Bądź wierny sobie. Pamiętaj, że gdy otwierają się wrota nieznanego - do Ciebie należy następny ruch.",

      "elementImage" : require('../dynamic/thirdview/assets/3/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/3/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/3/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/3/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/3/light.svg').default,

      "theme" : "#95481D"
    },

    // 4 //
    
    {
      "titles": "ANSUZ",

      "nameNumber": "Aett I, runa nr 4",

      "meaning": "Wyższe bóstwo (As), ród bogów, usta",

      "otherNames": "Ansur, Asur, As, Os",

      "elementName" : "Woda",

      "zodiacName" : "Merkury",

      "numerology" : "12",

      "cardNumber" : "4",

      "letter" : "A",

      "meaningTitle": "RUNA KOMUNIKACJI I NAUKI",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do Asów i boga Odyna. Symbolizuje usta. Odnosi się w dwójnasób do komunikacji, zarówno wypowiadania słów jak również słuchania i interpretacji otrzymanej informacji.",

      "aboutSecond": "Czerp ze źródła wiedzy, aby Twa mądrość jak wiatr pyłki kwiatów, niosła plon na nowe ziemie. Niech Twa mowa niesie harmonię.",

      "aboutThird": "Słowa mogą być jak woda, która gasi pragnienie i jak gorący ogień, gdy jest taka potrzeba. Wykorzystaj swoje doświadczenie na drodze do wyzwolenia osobowości.",

      "elementImage" : require('../dynamic/thirdview/assets/4/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/4/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/4/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/4/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/4/light.svg').default,

      "theme" : "#F2DE00"
    },

    // 5 //

    {
      "titles": "RAIDHO",

      "nameNumber": "Aett I, runa nr 5",

      "meaning": "Podróż, koło, jazda wierzchem",

      "otherNames": "Raido, Rad, Ried, Riad, Reid, Rit",

      "elementName" : "Ogień",

      "zodiacName" : "Znak Strzelca",

      "numerology" : "17",

      "cardNumber" : "5",

      "letter" : "R",

      "meaningTitle": "RUNA PODRÓŻY",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do podróżowania, jazdy wierzchem. Symbolizuje ruch, nie tylko ten związany ze zmianą miejsca. Pomocna w podróżach fizycznych i duchowych.",

      "aboutSecond": "Pokieruj swoim rozwojem. Przejmij ster własnego życia. Postaw na systematyczność. Podejmując szybkie i konkretne działania wprowadzisz w swoje życie istotne zmiany.",

      "aboutThird": "Zaplanuj drogę i wyrusz w podróż po oczekiwane rezultaty. Podążaj własną drogą. Pamiętaj, że celem ludzkiej podróży jest zawsze Dom.",

      "elementImage" : require('../dynamic/thirdview/assets/5/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/5/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/5/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/5/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/5/light.svg').default,

      "theme" : "#970097"
    },

    // 6 //

    {
      "titles": "KENAZ",

      "nameNumber": "Aett I, runa nr 6",

      "meaning": "Pochodnia, płonący ogień",

      "otherNames": "Kaunan, Kauna, Kaunaz, Kano, Ken",

      "elementName" : "Ogień",

      "zodiacName" : "Znak Barana",

      "numerology" : "28",

      "cardNumber" : "6",

      "letter" : "K",

      "meaningTitle": "RUNA TWÓRCZEJ INICJATYWY",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje  do płomienia pochodni. Symbolizuje wewnętrzny ogień. Oznacza entuzjazm, a także konstruktywne dążenie do samorealizacji.",

      "aboutSecond": "Otwieraj się na nowe możliwości i dbaj o jasność myśli. Upewnij się, że Twoje działania są ukierunkowane na osiągnięcie celu. Pamiętaj, że motywacja jest niczym bez chęci do działania. ",

      "aboutThird": "Kenaz oznacza też twórczość i nadawanie odpowiednich kształtów. Wykorzystaj swoją energię, aby Twoje życie uzyskało wymiar zgodny z upragnioną formą",

      "elementImage" : require('../dynamic/thirdview/assets/6/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/6/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/6/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/6/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/6/light.svg').default,

      "theme" : "#F04F00"
    },

    // 7 //

    {
      "titles": "GEBO",

      "nameNumber": "Aett I, runa nr 7",

      "meaning": "Dar bogów, połączenie",

      "otherNames": "Gyfu, Gifu, Geofu, Gyfu",

      "elementName" : "Woda",

      "zodiacName" : "Znak Wenus",

      "numerology" : "6",

      "cardNumber" : "7",

      "letter" : "G",

      "meaningTitle": "RUNA OBDAROWANIA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do daru bogów i połączenia. Gebo to szczodrość i sprawiedliwa wymiana. Kluczowa jest kwestia brania i dawania, która prowadzi do równowagi. To także symbol najpiękniejszego daru jaki można ofiarować - miłości.",

      "aboutSecond": "Bądź gotowy przyjąć odpowiedzialność za swoje działania. Umiejętność przyjmowania darów jest równie istotna jak szlachetna cnota dawania.",

      "aboutThird": "Możesz być darem dla innych i skarbem dla tego świata. Każdy jest zawsze osobno, choć możemy być też razem.",

      "elementImage" : require('../dynamic/thirdview/assets/7/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/7/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/7/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/7/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/7/light.svg').default,

      "theme" : "#D912B1"
    },

    // 8 //

    {
      "titles": "WUNJO",

      "nameNumber": "Aett I, runa nr 8",

      "meaning": "Wygrana, zwycięstwo, szczęście",

      "otherNames": "Wynn, Wenne",

      "elementName" : "Powietrze",

      "zodiacName" : "Znak Bliźniąt",

      "numerology" : "22",

      "cardNumber" : "8",

      "letter" : "W",

      "meaningTitle": "RUNA ZWYCIĘSTWA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do spełnienia, radości, tyumfu. Zapowiada dobrą passę. Zwycięstwo.",

      "aboutSecond": "Jesteś na dobrej drodze do szczęścia. Realizuj zaplanowane zadania. Uwolnij swoją energię. Pozwól sobie na miłość bezwarunkową. Otwórz się na szczęście i zadowolenie. Doceniaj innych i siebie.",

      "aboutThird": "Staraj się widzieć głębiej, dostrzegając to co nieoczywiste. Otwórz się na szczęście, aby poznać smak zwycięstwa.",

      "elementImage" : require('../dynamic/thirdview/assets/8/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/8/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/8/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/8/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/8/light.svg').default,

      "theme" : "#F6007B"
    },
        
    
    // 9 //

    {
      "titles": "HAGALAZ",

      "nameNumber": "Aett II, runa nr 9",

      "meaning": "Grad, wiedźma, Bogini Hagal",

      "otherNames": "Hagall, Hagel",

      "elementName" : "Woda",

      "zodiacName" : "Planeta Pluton",

      "numerology" : "11",

      "cardNumber" : "9",

      "letter" : "H",

      "meaningTitle": "RUNA PRZEKSZTAŁCENIA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do gradu. Znane są  także interpretacje w skojarzeniu do Bogini Hagal. Słowem kluczowym jest przesilenie. Okres transformacji energii. Niektóre zmiany dopiero z perspektywy czasu mogą okazać się korzystne.",

      "aboutSecond": "Przygotuj się na czas w którym warto odpuścić potrzebę kontroli. Hagal zwiastuje przemianę duchową. Nie próbuj niczego przyspieszać. Blokowanie energii tej runy może nieść stagnację. Hagalaz jest także runą ochrony i intuicji.",

      "aboutThird": "Pamiętaj, że pewne aspekty życia trzeba porzucić by pójść dalej.",

      "elementImage" : require('../dynamic/thirdview/assets/9/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/9/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/9/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/9/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/9/light.svg').default,

      "theme" : "#393F3E"
    },

    // 10 //

    {
      "titles": "NAUTHIZ",

      "nameNumber": "Aett II, runa nr 10",

      "meaning": "Potrzeba, konieczność",

      "otherNames": "Nauthiz, Naudiz, Nied, Nyd",

      "elementName" : "Ziemia",

      "zodiacName" : "Planeta Saturn",

      "numerology" : "7",

      "cardNumber" : "10",

      "letter" : "N",

      "meaningTitle": "RUNA PRÓBY",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do konieczności, rozumianej jako przymus losu; test pewności. Runa ta narzuca konieczność powrotu do prawd wewnętrznych.",

      "aboutSecond": "Czyń właściwy użytek z własnej energii. Możliwe trudności, uda Ci się pokonać dzięki Twojej wytrwałości. Pamiętaj o swojej motywacji. Runa ta prowadzi Cię pod wpływem nacisku.",

      "aboutThird": "To wyzwanie będące testem przetrwania. Wyzwolenie Twojego instynktu samozachowawczego. Pomoże Ci wygrać walkę z przeciwnościami losu.",

      "elementImage" : require('../dynamic/thirdview/assets/10/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/10/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/10/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/10/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/10/light.svg').default,

      "theme" : "#727272"
    },

    // 11 //

    {
      "titles": "ISA",

      "nameNumber": "Aett II, runa nr 11",

      "meaning": "Lód, siła statyczna",

      "otherNames": "Eisaz, Isaz",

      "elementName" : "Woda",

      "zodiacName" : "Planeta Neptun",

      "numerology" : "9",

      "cardNumber" : "11",

      "letter" : "I",

      "meaningTitle": "RUNA WOLI DUCHA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do lodu, który jako stan skupienia i siła ściskająca - niesie stabilizację, ochronę.",

      "aboutSecond": "Zastanów się nad tym co jest dla Ciebie istotne.  To dobry czas aby przeanalizować działania i odczekać na bardziej korzystny moment. Skoncentruj się na zamiarach i przemyśl jaka droga będzie najlepszą do osiągnięcia wyznaczonego celu.",

      "aboutThird": "Czasami dobrze jest się zdystansować. Skupienie i medytacja pozwolą Ci odblokować przepływ energii przez czakramy. Każda sytuacja pozwala dotrzeć do upragnionego rezultatu.",

      "elementImage" : require('../dynamic/thirdview/assets/11/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/11/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/11/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/11/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/11/light.svg').default,

      "theme" : "#D1D1D1"
    },

    // 12 //

    {
      "titles": "JERA",

      "nameNumber": "Aett II, runa nr 12",

      "meaning": "Rok, urodzaj, żniwa",

      "otherNames": "Jeran, Jara, Ar",

      "elementName" : "Ziemia",

      "zodiacName" : "Planeta Jowisz",

      "numerology" : "4",

      "cardNumber" : "12",

      "letter" : "J",

      "meaningTitle": "RUNA WZROSTU",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do plonów, urodzaju, wzrostu i regeneracji. Zaistniej w spirali życia.",

      "aboutSecond": "Wykaż się zaangażowaniem i cierpliwością. Staraj się w najlepszy sposób korzystać z potencjału, który posiadasz. Przy uporczywym wysiłku zbierzesz obfite plony. Dołóż wszelkich starań. Przygotuj grunt na nowe.",

      "aboutThird": "Myśl jest jak ziarno, które kiełkuje na gruncie fizycznego planu. Wszystko odbywa się w zgodzie z naturalnym porządkiem. Harmonia oznacza zbiory we właściwym czasie.",

      "elementImage" : require('../dynamic/thirdview/assets/12/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/12/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/12/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/12/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/12/light.svg').default,

      "theme" : "#9FDA00"
    },

    // 13 //

    {
      "titles": "EIHWAZ",

      "nameNumber": "Aett II, runa nr 13",

      "meaning": "Cis (drzewo z którego robiono łuki)",

      "otherNames": "Eoh, Iwaz, Yo, Yr",

      "elementName" : "Powietrze",

      "zodiacName" : "Planeta Uran",

      "numerology" : "5",

      "cardNumber" : "13",

      "letter" : "Ei",

      "meaningTitle": "RUNA WYTRWAŁOŚCI",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do nazwy drzewa (cis), będącego symbolem potencjału siły woli i długowieczności.",

      "aboutSecond": "Wyraź swoją pewność siebie. Korzystaj ze swojej charyzmy. Twoja wytrwałość w dążeniu do celu pozwoli osiągnąć zamierzony skutek. Utrzymuj ład i pożądek w swoim życiu.",

      "aboutThird": "Drzewo z którego wykonywane były łuki jest także symbolem prawidłowej oceny sytuacji i zamiaru, który jak strzała naprężona na cięciwie, trafia do wyznaczonego celu. Eihwaz to także symbol wiecznego życia; odradzania i budzenia się do życia.",

      "elementImage" : require('../dynamic/thirdview/assets/13/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/13/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/13/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/13/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/13/light.svg').default,

      "theme" : "#006CF2"
    },

    // 14 //

    {
      "titles": "PERTHO",

      "nameNumber": "Aett II, runa nr 14",

      "meaning": "To co ukryte, kobiece łono, kostka do gry",

      "otherNames": "Perdo, Perthro, Peorth",

      "elementName" : "Ziemia",

      "zodiacName" : "Znak Skorpiona",

      "numerology" : "3",

      "cardNumber" : "14",

      "letter" : "P",

      "meaningTitle": "RUNA NIEWIADOMEJ",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do tego co ukryte, jeszcze nieobjawione. Odnosi się do wewnętrznej mądrości, ale także do gier losowych.",

      "aboutSecond": "Zaistniej aby poznać swoje prawdziwe oblicze. Przyjmij los taki jakim jest, ale pamiętaj, że aby coś osiągnąć, należy podjąć ryzyko. Dogłębnie przemyśl zasadę skutku i przyczyny. Wystrzegaj się nałogów.",

      "aboutThird": "Zmiany są naturalnym procesem na ścieżce doświadczania. Upewnij się, czy rezultat Twoich działań został świadomie zamierzony.",

      "elementImage" : require('../dynamic/thirdview/assets/14/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/14/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/14/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/14/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/14/light.svg').default,

      "theme" : "#DFCD9F"
    },

    // 15 // 

    {
      "titles": "ALGIZ",

      "nameNumber": "Aett II, runa nr 15",

      "meaning": "Rogi łosia, opieka",

      "otherNames": "Elhaz, Eolh",

      "elementName" : "Ziemia",

      "zodiacName" : "Znak Raka",

      "numerology" : "9",

      "cardNumber" : "15",

      "letter" : "Z",

      "meaningTitle": "RUNA OCHRONNA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do rogów łosia, będącego opiekunem puszczy i naturalnego ekosystemu w środowisku, którego jest mieszkańcem.",

      "aboutSecond": "Pamiętaj o wyrozumiałości, także dla własnego oblicza. Zaakceptuj swoje słabości. Otaczaj opieką siebie i innych. Bądź wsparciem i wiedz, że wszechświat manifestuje opiekę nad Tobą w najbardziej korzystny sposób, w danym momencie.",

      "aboutThird": "Algiz to runa silnie ochronna. Najwyższa energia wszechświata otacza Cię opieką. Chroni przed niekorzystnymi okolicznościami losu i negatywnym promieniowaniem.",

      "elementImage" : require('../dynamic/thirdview/assets/15/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/15/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/15/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/15/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/15/light.svg').default,

      "theme" : "#7B3C26"
    },

    // 16 // 

    {
      "titles": "SOWILO",

      "nameNumber": "Aett II, runa nr 16",

      "meaning": "Słońce, energia, żagiel",

      "otherNames": "Sowelo, Sol, Sighel, Sigel, Sigi",

      "elementName" : "Ogień",

      "zodiacName" : "Słońce",

      "numerology" : "1",

      "cardNumber" : "16",

      "letter" : "S",

      "meaningTitle": "RUNA ZWYCIĘSTWA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do energii słonecznej, przypominając o konfrontacji jasności z ciemnością",

      "aboutSecond": "Stawiaj pewne i świadome kroki aby osiągnąć zwycięstwo. Jasno sprecyzuj swoją drogę. Przyjmuj okoliczności w ich prawdziwym świetle. Miej wiarę i nadzieję. Bądź kreatorem swojego życia. Bądź hojny jak słońce. Pamiętaj, że ono każdemu świeci po równo.",

      "aboutThird": "Sowilo prowadzi Cię do zwycięstwa. Odrzuć lęki. Miej odwagę. Zawalcz o to na czym naprawdę Ci zależy.",

      "elementImage" : require('../dynamic/thirdview/assets/16/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/16/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/16/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/16/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/16/light.svg').default,

      "theme" : "#D79635"
    },


    // 17 // 

    {
      "titles": "TEIWAZ",

      "nameNumber": "Aett III, runa nr 17",

      "meaning": "Wojownik, imię boga Tyra",

      "otherNames": "Tiwaz, Tyr",

      "elementName" : "Powietrze",

      "zodiacName" : "Znak Koziorożca",

      "numerology" : "3",

      "cardNumber" : "17",

      "letter" : "T",

      "meaningTitle": "RUNA WOJOWNIKA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do imienia  boga wojny - Tyra, którego charakteryzuje męstwo i odwaga.",

      "aboutSecond": "Precyzyjnie określ cel i miej odwage dążyć do osiągnięcia go bez względu na okoliczności. Uwierz w siebie. Niech prowadzi Cię przemyślana dokładność, upór i wiara w sprawiedliwość.",

      "aboutThird": "Teiwaz to znak wojownika. Przyjmij konsekwencje swoich działań i wyborów. Zawalcz o siebie.",

      "elementImage" : require('../dynamic/thirdview/assets/17/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/17/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/17/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/17/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/17/light.svg').default,

      "theme" : "#D100E4"
    },


    // 18 // 


    {
      "titles": "BERKANO",

      "nameNumber": "Aett III, runa nr 18",

      "meaning": "Brzoza; pierś matki",

      "otherNames": "Beorc, Berkanan, Berkana, Bjarkan",

      "elementName" : "Woda",

      "zodiacName" : "Księżyc",

      "numerology" : "2",

      "cardNumber" : "18",

      "letter" : "B",

      "meaningTitle": "RUNA NARODZIN",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do brzozy będącej symbolem płodności. Zwiastuje wiosnę - nowy początek. Brzoza jest wiotka, ale nawet silny wiatr nie jest w stanie jej złamać.",

      "aboutSecond": "Otwórz się na nowy początek. Zbliż się do natury. Myśl duchem, nie ciałem. Odczuwaj z poziomu serca. Okazuj troskę i pozwól innym zatroszczyć się o Ciebie.",

      "aboutThird": "Berkano to najczystsza żeńska energia. Nowy początek. Bezpieczeństwo i komfort istnienia.",

      "elementImage" : require('../dynamic/thirdview/assets/18/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/18/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/18/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/18/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/18/light.svg').default,

      "theme" : "#005C16"
    },
  

     // 19 // 


    {
      "titles": "EHWAZ",

      "nameNumber": "Aett III, runa nr 19",

      "meaning": "Koń, transformacja, zmiany",

      "otherNames": "Eoh, Eh",

      "elementName" : "Ogień",

      "zodiacName" : "Znak Wagi",

      "numerology" : "4",

      "cardNumber" : "19",

      "letter" : "E",

      "meaningTitle": "RUNA WSPÓŁISTNIENIA",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do konia jako symbolu mądrości i dokonywania zmian.",

      "aboutSecond": "Podejmij inicjatywę. Nastaw się na ruch do przodu. Bądź w gotowości do współpracy i współistnienia.  Zachowaj równowagę, mając umiar w braniu i dawaniu. Otwórz się na innych ludzi, a także inne poglądy.",

      "aboutThird": "Ehwaz zwraca uwagę na to, że współtworzenie to wzrost. Umiejętność współistnienia z szacunkiem dla tego co odmienne prowadzi do zjednoczenia.",

      "elementImage" : require('../dynamic/thirdview/assets/19/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/19/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/19/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/19/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/19/light.svg').default,

      "theme" : "#05FFC2"
    },

  
    // 20 // 


    {
      "titles": "MANNAZ",

      "nameNumber": "Aett III, runa nr 20",

      "meaning": "Człowiek, mężczyzna, mąż",

      "otherNames": "Mann, Madr, Man",

      "elementName" : "Powietrze",

      "zodiacName" : "Planeta Mars",

      "numerology" : "8",

      "cardNumber" : "20",

      "letter" : "M",

      "meaningTitle": "RUNA BYCIA SOBĄ",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do człowieka jako istoty. Drogą do doskonałości jest poznawanie samego siebie.",

      "aboutSecond": "Ujawnij przed sobą swoje mocne i słabe strony, aby w dalszej drodze towarzyszyły Ci odwaga i pewność siebie. Dzięki temu będziesz w stanie w pełni realizować swój potencjał. Oprócz siebie nie masz nic do stracenia. Żyj w harmonii.",

      "aboutThird": "Mannaz to runa, która zwraca uwagę na to iż każdy, niezależnie od wyborów i decyzji - jest inny i jednocześnie taki sam u źródła. Bądź sobą. Żyj w zgodzie z własnym kodeksem prawdy.",

      "elementImage" : require('../dynamic/thirdview/assets/20/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/20/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/20/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/20/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/20/light.svg').default,

      "theme" : "#AAB5B7"
    },


    // 21 // 


    {
      "titles": "LAGUZ",

      "nameNumber": "Aett III, runa nr 21",

      "meaning": "Woda, jezioro, ocean",

      "otherNames": "Laukaz, Lagu",

      "elementName" : "Woda",

      "zodiacName" : "Znak Ryb",

      "numerology" : "2",

      "cardNumber" : "21",

      "letter" : "L",

      "meaningTitle": "RUNA INTROSPEKCJI",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do wody jako życiodajnej energii. Oznacza umiejętność żeglowania na wodach życia. Symbolizuje emocjonalne oczyszczenie.",

      "aboutSecond": "Zanurz się wgłąb siebie. Posłuchaj głosu intuicji. Poczuj miłość. Pozwól by wypłynęło na wierzch to co nieujawnione przedtem. Zdaj się na instynkt i nie próbuj zrozumieć. Ucz się od siebie. Niech prowadzi Cię siła spokoju.",

      "aboutThird": "Lagus sprzyja refleksji. Wsłuchaj się w siebie i czerp z tego źródła wiedzy. Wszystkie odpowiedzi są w Tobie. To czas oczyszczenia.",

      "elementImage" : require('../dynamic/thirdview/assets/21/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/21/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/21/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/21/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/21/light.svg').default,

      "theme" : "#96FF00"
    },


    // 22 // 


    {
      "titles": "INGWAZ",

      "nameNumber": "Aett III, runa nr 22",

      "meaning": "imię boga Ingusa, odpoczynek, ekspansja",

      "otherNames": "Inguz, Ing",

      "elementName" : "Powietrze",

      "zodiacName" : "Znak Wodnika",

      "numerology" : "6",

      "cardNumber" : "22",

      "letter" : "NG",

      "meaningTitle": "RUNA PRZEMIANY",

      "aboutFirst": "Tradycyjne znaczenie runy nawiązuje do imienia boga Ingusa (Frey) - patrona uciech. Symbolizuje odpoczynek przed dalszą drogą. Zakończenie i nowy początek. Przemiana.",

      "aboutSecond": "Wytrwaj w realizacji swojego celu. Masz w sobie stosowną energię by zakończyć pewien etap i cieszyć się z pozytywnych rezultatów. Miej świadomość własnej siły osobistej i mądrze zarządzaj swoimi zasobami.",

      "aboutThird": "Ingwaz przypomina o tym, że życie to ciągła wędrówka. Chroni nowe przedsięwzięcia i daje czas na dojrzewanie w spokoju.",

      "elementImage" : require('../dynamic/thirdview/assets/22/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/22/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/22/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/22/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/22/light.svg').default,

      "theme" : "#FCC8FF"
    },


    // 23 // 


    {
      "titles": "DAGAZ",

      "nameNumber": "Aett III, runa nr 23",

      "meaning": "dzień, światło",

      "otherNames": "Daeg, Dagr",

      "elementName" : "Ogień",

      "zodiacName" : "Znak Wodnika",

      "numerology" : "1",

      "cardNumber" : "23",

      "letter" : "D",

      "meaningTitle": "RUNA POJEDNANIA",

      "aboutFirst": "Tradycyjne znaczenie runy to światło nowego dnia. Oznacza spełnienie, przebudzenie, oświecenie.",

      "aboutSecond": "Objaw sobie prawdę. Staraj się mieć jasny ogląd rzeczywistości. Przyjżyj się zależnościom pomiędzy tym co małe i tym co jest dla Ciebie nader istotne. Bądź światłem dla innych i nie bój się nim emanować. Pozwól sobie zobaczyć światło Twojego wnętrza.",

      "aboutThird": "Dagaz przypomina to tym, że koniec wieńczy dzieło. To talizman dla wszystkich i wszystkiego. Chroni przed zwątpieniem.",

      "elementImage" : require('../dynamic/thirdview/assets/23/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/23/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/23/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/23/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/23/light.svg').default,

      "theme" : "#FFFFFF"
    },


    // 24 // 


    {
      "titles": "OTHALA",

      "nameNumber": "Aett III, runa nr 24",

      "meaning": "dziedzictwo, dom, ojczyznę",

      "otherNames": "Odala, Odal, Othila, Othel",

      "elementName" : "Ziemia",

      "zodiacName" : "Znak Byka",

      "numerology" : "7",

      "cardNumber" : "24",

      "letter" : "O",

      "meaningTitle": "RUNA DZIEDZICTWA",

      "aboutFirst": "Tradycyjne znaczenie runy oznacza rodowe dziedzictwo. To runa powrotu do własnych korzeni. Oznacza harmonię, bezpieczeństwo i pokój w rodzinie.",

      "aboutSecond": "Poczuj się bezpiecznie w miejscu, którym jesteś. Przypomnij sobie gdzie i czym jest dla Ciebie Twój dom. Nie bój się przeszłości. Pamiętaj, że zdobyte doświadczenia to ziarna przyszłych plonów obfitości i spełnienia.",

      "aboutThird": "Othala często odnosi się do powiększenia stanu posiadania, majątku; tego co można przekazać swoim następcom.",

      "elementImage" : require('../dynamic/thirdview/assets/24/element.svg').default,

      "zodiacImage" : require('../dynamic/thirdview/assets/24/zodiac.svg').default,

      "runeImageCard" : require('../dynamic/thirdview/assets/24/rune.svg').default,

      "runeBorder" : require('../dynamic/thirdview/assets/24/border.svg').default,

      "runeLight" : require('../dynamic/thirdview/assets/24/light.svg').default,

      "theme" : "#0017F0"
    },
    

]