import React from 'react';
import '../dynamic/Dynamic.css';
import '../dynamic/slider/Slider.css';
import Slider from '../dynamic/slider/Slider';
import Start from '../dynamic/start/Start.js';
import SecondView from '../dynamic/secondview/SecondView';
import ThirdView from '../dynamic/thirdview/Thirdview';
import Maincard from '../dynamic/cards/Maincard';
import DatePopUp from './popups/DatePopUp';
import MobileButtonRight from './mobileRightButton';
import { StoreProvider } from './Store'

// const body = document.querySelector('body')



// const SwiperEl = document.querySelector('.swiper-wrapper')
// let SwiperElPosition = window.getComputedStyle(SwiperEl).transform
// var matrixValueArr = SwiperElPosition.split(" ")
// var StringValue = matrixValueArr[4]
// var XValue = parseInt(StringValue)
// if (XValue < -30) {
//     body.classList.add('firstSlide')
// }
// if (XValue > 1) {
//     SwiperElPosition = 'matrix(1, 0, 0, 1, 0,'
// }
// }}

export let XvalueStart = 0;
export let XvalueEnd = 0;


class Dynamicbox  extends React.Component {
    render() {
        var clientX;
        var deltaX;
    return (
        <div className="Dynamic-box" 
        
        onTouchStart={ (e) => {
                clientX = e.touches[0].clientX;
                // console.log(clientX)
                return XvalueStart = clientX
        }}
        onTouchEnd={ (e) => {
            deltaX = e.changedTouches[0].clientX
            // console.log(deltaX)
            return XvalueEnd = deltaX
        }}
        // onClick = {() => {
        //     console.log(XvalueStart)
        // }}
        >
           <StoreProvider>
            <Start />
            <SecondView />
            <Slider />
            <Maincard  />
            <DatePopUp />
            <MobileButtonRight />
            <ThirdView />
            </StoreProvider>
        </div>
        )
}
}

export default Dynamicbox;